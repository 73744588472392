<template>
    
    <div v-if="files" class="h-full w-full flex flex-col">

        <div class="h-auto flex flex-row justify-end items-center p-2">
            <div class="relative flex-none w-84">
                <input type="text" placeholder="Search" v-model="searchInput"
                    class="w-full h-8 rounded bg-body flex flex-row justify-start items-center
                    px-2">
            </div>
        </div>

        <div class="flex-1 flex flex-row overflow-hidden mt-2">

            <div class="h-full w-full overflow-auto pb-6">
                <div class="scroll-gradient"></div>
                <CommAndPodcast v-for="(el, index) in search(files)" :key="index" :data="el" />
            </div>

            <!-- <div class="h-full w-1/2 overflow-auto pb-6">
                <div class="scroll-gradient"></div>
                <CommAndPodcast v-if="el.type !== 'EY Podcasts'" v-for="(el, index) in search(files)" :key="index" :data="el" />
            </div> -->

        </div>

    </div>
    
    <div v-else class="h-full w-full flex flex-col justify-center items-center">
        <loader :loading="loading" />
    </div>

</template>

<script>
const CommAndPodcast = () => import('@/components/Desktop/PersonalDashboard/CommonAndPodcast');
import { state } from '@/store';

export default {
    components: {
        CommAndPodcast
    },
    data() {
        return {
            files: null,
            loading: false,
            searchInput: ''
        }
    },
    methods: {
        load() {
            this.loading = true
            this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=CommAndPodcast&sorts[date]=DESC&filters[active]=1', {params: state.globalParams})
                .then(response => {
                    this.loading = false
                    this.files = response.data
                })
        },
        search(data) { return this.$search(data, this.searchInput) }
    },
    computed: {
        params() {
            let params = state.globalParams
            if (this.$route.params.catId) {
                params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
            }
            return params
        }
    },
    watch: {
        params() {
            this.load();
        }
    },
    mounted() {
        this.load();

        setInterval(() => {
            this.load();
        }, state.refreshPersonalDashboard);
    }
}
</script>